// src/components/UserFeed.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './UserFeed.css'; // Create and style accordingly

function UserFeed() {
  const { id } = useParams();
  const [feedItems, setFeedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (!id) {
      setError('No user ID provided.');
      setLoading(false);
      return;
    }

    const fetchFeed = async () => {
      try {
        const response = await fetch(`${apiUrl}/feed/${id}`, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 401) {
          // Redirect to login or handle unauthorized access
          window.location.href = '/login';
          return;
        }

        if (!response.ok) {
          const errorMsg = await response.text();
          throw new Error(errorMsg || 'Failed to fetch feed.');
        }

        const data = await response.json();
        setFeedItems(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchFeed();
  }, [id, apiUrl]);

  if (loading) {
    return (
      <div className="user-feed-page">
        <p>Loading feed...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="user-feed-page">
        <p className="error">{error}</p>
      </div>
    );
  }

  return (
    <div className="user-feed-page">
      <h2>User Feed</h2>
      {feedItems.length === 0 ? (
        <p>No feed items available.</p>
      ) : (
        <div className="feed-items">
          {feedItems.map((item) => (
            <div key={item.id} className="feed-item">
              <div className="feed-header">
                {item.authorAvatarUrl && (
                  <img
                    src={item.authorAvatarUrl}
                    alt={`${item.authorName}'s avatar`}
                    className="feed-avatar"
                  />
                )}
                <div>
                  <h3>{item.authorName}</h3>
                  <p>{new Date(item.timestamp).toLocaleString()}</p>
                </div>
              </div>
              <p className="feed-content">{item.content}</p>
              {item.imageUrl && (
                <img
                  src={item.imageUrl}
                  alt="Feed content"
                  className="feed-image"
                />
              )}
              <div className="feed-actions">
                <span>👍 {item.likesCount}</span>
                <span>💬 {item.commentsCount}</span>
                <span>🔄 {item.sharesCount}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default UserFeed;
