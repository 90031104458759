// src/components/AcceptedConnections.js

import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './AcceptedConnections.css';
import { AuthContext } from '../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AcceptedConnections() {
  const { user, loading: authLoading } = useContext(AuthContext); // Access 'user' and 'loading' from AuthContext
  const navigate = useNavigate();
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // State variables for modal functionality
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState('');
  const messageTextareaRef = useRef(null);

  // Environment variable for API URL
  const apiUrl = process.env.REACT_APP_API_URL;

  // Function to fetch accepted connections
  const fetchConnections = useCallback(async () => {
    if (authLoading) {
      // Wait for AuthContext to finish loading
      return;
    }

    if (!user) {
      // If user is not authenticated, redirect to login
      toast.error('You are not logged in. Please log in.');
      setLoading(false);
      navigate('/login');
      return;
    }

    try {
      console.log('Fetching accepted connections...');
      const response = await fetch(`${apiUrl}/connections/accepted`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Ensure cookies are sent with the request
      });

      if (!response.ok) {
        if (response.status === 401) {
          // Unauthorized access; redirect to login
          toast.error('Unauthorized access. Please log in again.');
          navigate('/login');
          return;
        } else {
          const errorData = await response.json();
          console.error('Error fetching connections:', errorData);
          toast.error(errorData.error || 'Failed to fetch connections.');
          setError(errorData.error || 'Failed to fetch connections.');
          return;
        }
      }

      const data = await response.json();
      console.log('Accepted connections fetched:', data);
      setConnections(data.acceptedConnections || []); // Safeguard against undefined
    } catch (err) {
      console.error('Error fetching accepted connections:', err);
      toast.error('An unexpected error occurred while fetching connections.');
      setError('An unexpected error occurred while fetching connections.');
    } finally {
      setLoading(false);
    }
  }, [user, apiUrl, navigate, authLoading]);

  useEffect(() => {
    fetchConnections();
  }, [fetchConnections]);

  const handleViewProfile = useCallback(
    (userId) => {
      navigate(`/profile/${userId}`);
    },
    [navigate]
  );

  // Handlers for sending messages via modal
  const handleOpenSendMessageModal = useCallback((user) => {
    setSelectedUser(user);
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setSelectedUser(null);
    setMessage('');
  }, []);

  const handleSendMessage = useCallback(async () => {
    if (!message.trim()) {
      toast.warn('Message cannot be empty.');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/messages/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          recipientEmail: selectedUser.email,
          content: message.trim(),
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to send message');
      }

      const data = await response.json();
      console.log('Message sent successfully:', data);
      toast.success('Message sent successfully!');
      handleCloseModal();
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error(`Error: ${error.message}`);
    }
  }, [apiUrl, message, selectedUser, handleCloseModal]);

  // Focus on the textarea when the modal opens
  useEffect(() => {
    if (showModal && messageTextareaRef.current) {
      messageTextareaRef.current.focus();
    }
  }, [showModal]);

  if (authLoading || loading) {
    return (
      <div className="accepted-connections-page">
        <div className="accepted-connections-container">
          <p className="loading">Loading accepted connections...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="accepted-connections-page">
        <div className="accepted-connections-container">
          <p className="error-message">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="accepted-connections-page">
      <div className="accepted-connections-container">
        <h2>My Accepted Connections</h2>
        {connections.length === 0 ? (
          <p>You have no accepted connections yet.</p>
        ) : (
          <ul className="connections-list">
            {connections.map((connUser) => (
              <li key={connUser.id} className="connection-item">
                <img
                  src={connUser.avatarUrl || '/default-avatar.jpg'}
                  alt={`${connUser.name}'s avatar`}
                  className="connection-avatar"
                  onClick={() => handleViewProfile(connUser.id)}
                  style={{ cursor: 'pointer' }}
                />
                <div className="connection-details">
                  <h3
                    className="connection-name"
                    onClick={() => handleViewProfile(connUser.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {connUser.name}
                  </h3>
                  <p className="connection-email">{connUser.email}</p>
                </div>
                {/* Send Message Button */}
                <button
                  className="send-message-button"
                  onClick={() => handleOpenSendMessageModal(connUser)}
                >
                  Send Message
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Toast Container for Notifications */}
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar />

      {/* Modal for Sending Messages */}
      {showModal && selectedUser && (
        <div className="modal-overlay" role="dialog" aria-modal="true">
          <div className="modal">
            <button
              className="close-modal-button"
              onClick={handleCloseModal}
              aria-label="Close message modal"
            >
              &times;
            </button>
            <h2>Send a Message to {selectedUser.name}</h2>
            <textarea
              ref={messageTextareaRef}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
              maxLength={1000} // Adjust character limit as needed
              rows={5}
              aria-label={`Message to ${selectedUser.name}`}
            ></textarea>
            <div className="modal-buttons">
              <button
                onClick={handleSendMessage}
                disabled={!message.trim()}
                className="send-button"
                aria-label="Send message"
              >
                Send
              </button>
              <button
                onClick={handleCloseModal}
                className="cancel-button"
                aria-label="Cancel sending message"
              >
                Cancel
              </button>
            </div>
            <div
              className={`char-count ${
                1000 - message.length <= 100 ? 'warning' : ''
              }`}
              aria-live="polite"
            >
              {1000 - message.length} characters remaining
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AcceptedConnections;
