// src/components/SharedFeed.js

import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './SharedFeed.css'; // Create corresponding CSS

function SharedFeed() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const [feedItems, setFeedItems] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userId) {
      setError('Invalid user ID');
      setLoading(false);
      return;
    }

    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`${apiUrl}/accounts/${userId}`, {
          credentials: 'include',
        });

        if (!response.ok) {
          if (response.status === 401) {
            navigate('/login');
            return;
          }
          throw new Error('Failed to fetch user information');
        }

        const data = await response.json();
        setUserInfo(data);
      } catch (err) {
        console.error('Error fetching user info:', err);
        setError(err.message);
        setUserInfo(null); // Ensure userInfo is reset on error
      }
    };

    const fetchFeedItems = async () => {
      try {
        const response = await fetch(`${apiUrl}/shared-feed/${userId}`, {
          credentials: 'include',
        });

        if (!response.ok) {
          if (response.status === 401) {
            navigate('/login');
            return;
          }
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to fetch shared feed');
        }

        const data = await response.json();
        setFeedItems(data.feedItems);
      } catch (err) {
        console.error('Error fetching shared feed:', err);
        setError(err.message);
        setFeedItems([]); // Optionally reset feedItems on error
      } finally {
        setLoading(false);
      }
    };

    // Fetch user info and feed items concurrently
    fetchUserInfo();
    fetchFeedItems();
  }, [userId, apiUrl, navigate]);

  const handleLike = useCallback(
    async (feedItemId) => {
      try {
        const response = await fetch(`${apiUrl}/shared-feed/${userId}/like/${feedItemId}`, {
          method: 'POST',
          credentials: 'include',
        });

        if (!response.ok) {
          if (response.status === 401) {
            navigate('/login');
            return;
          }
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to like feed item');
        }

        // Removed: const data = await response.json();

        // Update the likes count locally
        setFeedItems((prevItems) =>
          prevItems.map((item) =>
            item.id === feedItemId
              ? { ...item, likesCount: item.likesCount + 1 }
              : item
          )
        );
      } catch (error) {
        console.error('Error liking feed item:', error);
        alert(`Error: ${error.message}`);
      }
    },
    [apiUrl, userId, navigate]
  );

  const handleComment = useCallback((feedItemId) => {
    // Implement comment functionality (e.g., open a comment modal)
    alert('Comment functionality to be implemented.');
  }, []);

  if (loading) {
    return (
      <div className="shared-feed-page">
        <p className="loading">Loading feed...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="shared-feed-page">
        <p className="error-message">{error}</p>
      </div>
    );
  }

  if (!userInfo) {
    return (
      <div className="shared-feed-page">
        <p>No user information available.</p>
      </div>
    );
  }

  return (
    <div className="shared-feed-page">
      <div className="header">
        <h1>{userInfo.name}'s Feed</h1>
      </div>

      {/* User's Profile Info */}
      <div className="user-profile">
        <img
          src={userInfo.avatarUrl || '/default-avatar.png'}
          alt={`${userInfo.name}'s avatar`}
          className="avatar"
        />
        <div className="user-details">
          <h2>{userInfo.name}</h2>
          <p>{userInfo.bio}</p>
        </div>
      </div>

      {/* Feed Items */}
      <div className="feed-items">
        {feedItems.length > 0 ? (
          feedItems.map((item) => (
            <div key={item.id} className="feed-item">
              <div className="feed-header">
                <img
                  src={item.authorAvatarUrl || '/default-avatar.png'}
                  alt={`${item.authorName}'s avatar`}
                  className="avatar"
                />
                <div className="author-info">
                  <h3>{item.authorName}</h3>
                  <p className="timestamp">
                    {new Date(item.timestamp).toLocaleString()}
                  </p>
                </div>
              </div>
              <div className="feed-content">
                <p>{item.content}</p>
                {item.imageUrl && (
                  <img
                    src={item.imageUrl}
                    alt="Feed visual content"
                    className="feed-image"
                  />
                )}
              </div>
              <div className="feed-actions">
                <button onClick={() => handleLike(item.id)}>
                  Like ({item.likesCount})
                </button>
                <button onClick={() => handleComment(item.id)}>
                  Comment ({item.commentsCount})
                </button>
                {/* Add more actions as needed */}
              </div>
              {/* Optionally, display comments */}
            </div>
          ))
        ) : (
          <p>No feed items to display.</p>
        )}
      </div>
    </div>
  );
}

export default SharedFeed;
