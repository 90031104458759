// src/components/FeedItem.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './FeedItem.css';
import CommentSection from './CommentSection';
import { FaHeart, FaRegHeart, FaComment, FaShare, FaTrash } from 'react-icons/fa'; // Import FaTrash for the delete icon
import { motion, AnimatePresence } from 'framer-motion'; // For animations
import ImageWithFallback from './ImageWithFallback'; // Import the component

function FeedItem({ item, toggleLike, handleToggleComments, visibleComments, currentUserId, onDelete }) {
  const {
    id,
    authorId, // Ensure authorId is included in the item prop
    authorName,
    authorAvatarUrl,
    content,
    imageUrl,
    timestamp,
    likesCount,
    commentsCount,
    sharesCount,
    liked,
  } = item;

  const formattedTime = new Date(timestamp).toLocaleString();

  // State to manage the number of visible comments
  const [visibleCommentsCount, setVisibleCommentsCount] = useState(3); // Show first 3 comments by default

  // State to trigger burst animation
  const [showBurst, setShowBurst] = useState(false);

  // State to manage deletion loading and error
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState('');

  // Determine if the current user is the author
  const isAuthor = authorId === currentUserId;
  console.log(`FeedItem - Author ID: ${authorId}, Current User ID: ${currentUserId}, Is Author: ${isAuthor}`);

  // Handle Like Button Click
  const handleLikeClick = () => {
    toggleLike(id, liked);
    if (!liked) {
      setShowBurst(true);
      setTimeout(() => setShowBurst(false), 1000); // Duration of the burst animation
    }
  };

  // Handle Delete Button Click
  const handleDeleteClick = async () => {
    setIsDeleting(true);
    try {
      await onDelete(id);
      setIsDeleting(false);
    } catch (error) {
      console.error('Delete operation failed:', error);
      setDeleteError('Failed to delete the post.');
      setIsDeleting(false);
    }
  };

  return (
    <motion.div
      className="feed-item card"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Feed Item Header */}
      <div className="feed-item-header">
        <ImageWithFallback
          src={authorAvatarUrl}
          fallbackSrc="/helmetrobo.jpg"
          alt={`${authorName}'s avatar`}
          className="avatar"
        />
        <div className="author-info">
          <h4>{authorName}</h4>
          <p>{formattedTime}</p>
        </div>
      </div>

      {/* Feed Item Content */}
      <div className="feed-item-content">
        <p>{content}</p>
        {imageUrl && (
          <ImageWithFallback
            src={imageUrl}
            fallbackSrc="/helmetrobo.jpg"
            alt="Post visual content"
            className="post-image"
            loading="lazy"
          />
        )}
      </div>

      {/* Feed Item Actions */}
      <div className="feed-item-actions">
        {/* Like Button */}
        <motion.button
          className={`action-button like-button ${liked ? 'liked' : ''}`}
          onClick={handleLikeClick}
          aria-label={liked ? 'Unlike this post' : 'Like this post'}
          data-tooltip={liked ? 'Unlike' : 'Like'}
          whileTap={{ scale: 0.9 }}
          initial={false}
          animate={{
            scale: liked ? [1, 1.3, 1] : 1,
            color: liked ? '#e0245e' : 'var(--color-text-secondary)',
          }}
          transition={{ type: 'spring', stiffness: 300, damping: 10 }}
        >
          {liked ? <FaHeart /> : <FaRegHeart />} <span>{likesCount}</span>
        </motion.button>

        {/* Burst Animation */}
        <AnimatePresence>
          {showBurst && (
            <motion.div
              className="burst-container"
              initial={{ opacity: 1, scale: 0 }}
              animate={{ opacity: 0, scale: 2 }}
              exit={{ opacity: 0, scale: 2 }}
              transition={{ duration: 1 }}
            >
              <FaHeart className="burst-heart" />
              <FaHeart className="burst-heart" />
              <FaHeart className="burst-heart" />
            </motion.div>
          )}
        </AnimatePresence>

        {/* Comment Button */}
        <motion.button
          className="action-button comment-button"
          onClick={() => handleToggleComments(id)}
          aria-label="Toggle comments"
          data-tooltip="Comment"
          whileTap={{ scale: 0.9 }}
        >
          <FaComment /> <span>{commentsCount}</span>
        </motion.button>

        {/* Share Button */}
        <motion.button
          className="action-button share-button"
          onClick={() => {
            if (navigator.share) {
              navigator
                .share({
                  title: 'Check out this post!',
                  text: content,
                  url: window.location.href,
                })
                .catch((error) => console.error('Error sharing:', error));
            } else {
              alert('Share functionality is not supported in your browser.');
            }
          }}
          aria-label="Share this post"
          data-tooltip="Share"
          whileTap={{ scale: 0.9 }}
        >
          <FaShare /> <span>{sharesCount}</span>
        </motion.button>

        {/* Delete Button - Only visible to the post author */}
        {isAuthor && (
          <motion.button
            className="action-button delete-button"
            onClick={handleDeleteClick}
            aria-label="Delete this post"
            data-tooltip="Delete"
            whileTap={{ scale: 0.9 }}
            disabled={isDeleting} // Disable button while deleting
          >
            {isDeleting ? 'Deleting...' : <FaTrash />} {/* Show 'Deleting...' text when in progress */}
          </motion.button>
        )}
      </div>

      {/* Display deletion error if any */}
      {deleteError && <p className="error-message">{deleteError}</p>}

      {/* Comment Section */}
      {visibleComments[id] && (
        <CommentSection
          feedItemId={id}
          visibleCommentsCount={visibleCommentsCount}
          setVisibleCommentsCount={setVisibleCommentsCount}
        />
      )}
    </motion.div>
  );
}

FeedItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired, // Assuming id is a number
    authorId: PropTypes.number.isRequired, // Added authorId
    authorName: PropTypes.string.isRequired,
    authorAvatarUrl: PropTypes.string,
    content: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    timestamp: PropTypes.string.isRequired,
    likesCount: PropTypes.number.isRequired,
    commentsCount: PropTypes.number.isRequired,
    sharesCount: PropTypes.number.isRequired,
    liked: PropTypes.bool.isRequired,
  }).isRequired,
  toggleLike: PropTypes.func.isRequired,
  handleToggleComments: PropTypes.func.isRequired,
  visibleComments: PropTypes.object.isRequired,
  currentUserId: PropTypes.number, // Made it optional to handle null
  onDelete: PropTypes.func.isRequired, // New prop for delete callback
};

export default FeedItem;
