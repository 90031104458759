// src/components/Profile.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Profile.css';

function Profile() {
  const { id } = useParams(); // Get user ID from URL
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {

    if (!id) {
      setError('No user ID provided.');
      setLoading(false);
      return;
    }

    const fetchProfile = async () => {
      const url = `${apiUrl}/users/${id}`;

      try {
        const response = await fetch(url, {
          credentials: 'include',
        });


        if (!response.ok) {
          if (response.status === 404) {
            throw new Error('User not found.');
          } else if (response.status === 401) {
            navigate('/login');
            return;
          }
          throw new Error('Failed to fetch profile data.');
        }

        const data = await response.json();

        setProfileData(data); // Assuming backend sends data directly
        setLoading(false);
      } catch (err) {
        console.error('Error fetching profile data:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProfile();
  }, [id, apiUrl, navigate]);

  const handleViewFeed = () => {
    navigate(`/shared-feed/${id}`);
  };

  if (loading) {
    return (
      <div className="profile-page">
        <p className="loading">Loading profile...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="profile-page">
        <p className="error">{error}</p>
      </div>
    );
  }

  if (!profileData) {
    return (
      <div className="profile-page">
        <p>No profile data available.</p>
      </div>
    );
  }

  return (
    <div className="profile-page">
      <div className="profile-container">
        <div className="profile-header">
          {profileData.avatarUrl && (
            <img
              src={profileData.avatarUrl}
              alt={`${profileData.name}'s avatar`}
              className="avatar-image"
            />
          )}
          <h2 className="profile-name">{profileData.name}</h2>
        </div>
        <div className="profile-details">
          <p><strong>Email:</strong> {profileData.email}</p>
          {profileData.firstName && (
            <p><strong>First Name:</strong> {profileData.firstName}</p>
          )}
          {profileData.lastName && (
            <p><strong>Last Name:</strong> {profileData.lastName}</p>
          )}
          {profileData.bio && (
            <p><strong>Bio:</strong> {profileData.bio}</p>
          )}
          {/* Add more profile fields as necessary */}
        </div>
        <button className="view-feed-button" onClick={handleViewFeed}>
          View Feed
        </button>
      </div>
    </div>
  );
}

export default Profile;
