// src/components/SearchResults.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './SearchResults.css';

function SearchResults() {
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const location = useLocation();

  // State variables for modal functionality
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState('');
  const messageTextareaRef = useRef(null);

  // State to track connection statuses
  const [connections, setConnections] = useState({}); // { userId: 'connected' | 'pending' | 'none' }

  // Fetch Connection Statuses (Memoized with useCallback)
  const fetchConnectionStatuses = useCallback((fetchedUsers) => {
    // Assuming the backend has an endpoint to fetch connections
    fetch(`${apiUrl}/connections/status`, { // Note: Ensure the correct endpoint
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userIds: fetchedUsers.map((u) => parseInt(u.id, 10)), // Convert IDs to integers
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch connection statuses');
        }
        return response.json();
      })
      .then((data) => {
        // data.connections is expected to be an array of { userId, status }
        const statusMap = {};
        data.connections.forEach((conn) => {
          statusMap[conn.userId] = conn.status; // 'connected', 'pending', 'none'
        });
        setConnections(statusMap);
      })
      .catch((error) => {
        console.error('Error fetching connection statuses:', error);
      });
  }, [apiUrl]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryParam = searchParams.get('query') || '';
    setQuery(queryParam);

    if (queryParam.trim() === '') {
      setUsers([]);
      return;
    }

    // Fetch users matching the search term
    fetch(
      `${apiUrl}/search-users?query=${encodeURIComponent(queryParam.trim())}`,
      {
        credentials: 'include',
      }
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            navigate('/login');
          }
          throw new Error('Failed to fetch users');
        }
        return response.json();
      })
      .then((data) => {
        // **Ensure IDs are integers**
        const usersWithIntIds = data.users.map((u) => ({
          ...u,
          id: parseInt(u.id, 10), // Convert ID to integer
        }));
        setUsers(usersWithIntIds);
        // After fetching users, fetch connection statuses
        fetchConnectionStatuses(usersWithIntIds);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });
  }, [location.search, apiUrl, navigate, fetchConnectionStatuses]); // Included fetchConnectionStatuses

  useEffect(() => {
    if (showModal && messageTextareaRef.current) {
      messageTextareaRef.current.focus();
    }
  }, [showModal]);

  const handleUserClick = (user) => {
    navigate(`/account/${user.id}`);
  };

  const handleConnectClick = (user) => {
    // Send connection request
    fetch(`${apiUrl}/connections/request`, { // Note: Ensure the correct endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        targetUserId: parseInt(user.id, 10), // Convert ID to integer
      }),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            navigate('/login');
          }
          return response.json().then((err) => {
            throw new Error(err.error || 'Failed to send connection request');
          });
        }
        return response.json();
      })
      .then((data) => {
        // Update connection status
        setConnections((prev) => ({
          ...prev,
          [user.id]: 'pending',
        }));
        alert('Connection request sent!');
      })
      .catch((error) => {
        console.error('Error sending connection request:', error);
        alert(`Error: ${error.message}`);
      });
  };

  const handleViewClick = (user) => {
    navigate(`/profile/${user.id}`); // Navigate to the profile view
  };

  const handleSendMessage = () => {
    // Implement the send message functionality here
    if (!message.trim()) return; // Prevent sending empty messages

    fetch(`${apiUrl}/messages/send`, { // Note: Ensure the correct endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        recipientEmail: selectedUser.email, // Ensure recipientEmail is correct
        content: message.trim(),
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((err) => {
            throw new Error(err.error || 'Failed to send message');
          });
        }
        return response.json();
      })
      .then((data) => {
        setShowModal(false);
        setMessage('');
        alert('Message sent successfully!'); // Optional: Replace with better UI feedback
      })
      .catch((error) => {
        console.error('Error sending message:', error);
        alert(`Error: ${error.message}`); // Optional: Replace with better UI feedback
      });
  };

  const handleMessageClick = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  return (
    <div className="search-results-page">
      <div className="header">
        <h1>Search Results for "{query}"</h1>
      </div>

      {/* User List */}
      {users.length > 0 ? (
        <ul className="user-list">
          {users.map((u) => (
            <li key={u.id}>
              <div className="user-info" onClick={() => handleUserClick(u)}>
                {u.name} ({u.email})
              </div>
              <div className="button-group">
                <button
                  className="view-button"
                  onClick={() => handleViewClick(u)}
                  disabled={connections[u.id] !== 'connected'}
                  title={
                    connections[u.id] === 'connected'
                      ? 'View Profile'
                      : 'Connect to view profile'
                  }
                >
                  View
                </button>
                {connections[u.id] === 'connected' ? (
                  <>
                    <button className="connect-button connected" disabled>
                      Connected
                    </button>
                    <button
                      className="message-button"
                      onClick={() => handleMessageClick(u)}
                    >
                      Message
                    </button>
                  </>
                ) : connections[u.id] === 'pending' ? (
                  <button className="connect-button pending" disabled>
                    Pending
                  </button>
                ) : (
                  <button
                    className="connect-button"
                    onClick={() => handleConnectClick(u)}
                  >
                    Connect
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No users found.</p>
      )}

      {/* Modal for Sending Messages */}
      {showModal && selectedUser && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Send a message to {selectedUser.name}</h2>
            <textarea
              ref={messageTextareaRef}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
              maxLength={100} // Enforce character limit
            ></textarea>
            <div className="modal-buttons">
              <button
                onClick={handleSendMessage}
                disabled={!message.trim()}
              >
                Send
              </button>
              <button onClick={() => setShowModal(false)}>Cancel</button>
            </div>
            <div
              className={`char-count ${
                100 - message.length <= 10 ? 'warning' : ''
              }`}
            >
              {100 - message.length} characters remaining
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchResults;
