// src/components/Login.js

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // Adjust the path as needed
import './Login.css'; // Import custom styles

function Login() {
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const { login } = useContext(AuthContext); // Access the login function from AuthContext

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage('');
    setIsSubmitting(true);

    try {
      const result = await login(loginData);
      if (result.success) {
        navigate('/dashboard'); // Redirect to dashboard after successful login
      } else {
        setResponseMessage(result.error || 'Failed to login.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setResponseMessage('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="login-page">
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Login</h2>
        
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={loginData.email}
          onChange={handleChange}
          required
          placeholder="Enter your email"
        />

        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          value={loginData.password}
          onChange={handleChange}
          required
          placeholder="Enter your password"
        />

        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
          {isSubmitting ? 'Logging in...' : 'Login'}
        </button>

        {responseMessage && (
          <p className={`response ${responseMessage.toLowerCase().includes('error') ? 'error' : ''}`}>
            {responseMessage}
          </p>
        )}
      </form>
    </div>
  );
}

export default Login;
