// src/components/Inbox.js

import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './Inbox.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css/animate.min.css'; // Import Animate.css
// Removed BackButton import
// import BackButton from './BackButton'; // Importing the BackButton component

function Inbox() {
  const [inboxMessages, setInboxMessages] = useState([]);
  const [sentMessages, setSentMessages] = useState([]);
  const [mergedMessages, setMergedMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [replyContent, setReplyContent] = useState('');
  const [userEmail, setUserEmail] = useState(''); // State to store current user's email
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isSending, setIsSending] = useState(false); // Sending reply state
  const [actionFeedback, setActionFeedback] = useState({ type: '', message: '' }); // Feedback for actions
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL; // Ensure this is set in your environment variables

  // Ref to store WebSocket instance
  const ws = useRef(null);
  const reconnectTimer = useRef(null);
  const reconnectInterval = 5000; // 5 seconds

  // Fetch current user's email (Assuming there's an endpoint for it)
  useEffect(() => {
    fetch(`${apiUrl}/user-info`, {
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          navigate('/login');
          throw new Error('Not authenticated');
        }
        return response.json();
      })
      .then((data) => {
        setUserEmail(data.email);
      })
      .catch((error) => {
        console.error('Error fetching user info:', error);
        setActionFeedback({ type: 'error', message: 'Error fetching user information.' });
      });
  }, [apiUrl, navigate]);

  // Fetch inbox and sent messages
  useEffect(() => {
    if (!userEmail) return; // Ensure userEmail is available

    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([fetchInboxMessages(), fetchSentMessages()]);
      } catch (error) {
        // Errors are handled in fetch functions
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    // Set up WebSocket connection
    setupWebSocket();

    // Clean up on unmount
    return () => {
      if (ws.current) {
        ws.current.close();
      }
      if (reconnectTimer.current) {
        clearTimeout(reconnectTimer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail]); // Depend on userEmail to ensure it's set before connecting

  // Merge inbox and sent messages
  useEffect(() => {
    const combined = [
      ...inboxMessages.map(msg => ({ ...msg, type: 'received' })),
      ...sentMessages.map(msg => ({ ...msg, type: 'sent' }))
    ];

    // Sort messages by timestamp descending
    combined.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

    setMergedMessages(combined);
  }, [inboxMessages, sentMessages]);

  // Group messages by user email
  const groupedMessages = useMemo(() => {
    const groups = {};

    mergedMessages.forEach(msg => {
      // Determine the other party's email based on message type
      const otherPartyEmail = msg.type === 'sent' ? msg.recipientEmail : msg.senderEmail;

      if (!otherPartyEmail) return; // Skip if email is undefined

      if (!groups[otherPartyEmail]) {
        groups[otherPartyEmail] = {
          userEmail: otherPartyEmail,
          messages: []
        };
      }

      groups[otherPartyEmail].messages.push(msg);
    });

    // Convert the groups object to an array and sort by latest message timestamp
    return Object.values(groups).sort((a, b) => {
      const aLatest = new Date(a.messages[0].timestamp);
      const bLatest = new Date(b.messages[0].timestamp);
      return bLatest - aLatest;
    });
  }, [mergedMessages]);

  const fetchInboxMessages = async () => {
    try {
      const res = await fetch(`${apiUrl}/messages/inbox`, {
        credentials: 'include',
      });

      if (!res.ok) {
        if (res.status === 401) {
          navigate('/login');
        }
        const errorText = await res.text();
        throw new Error(errorText || 'Failed to fetch inbox messages');
      }

      const data = await res.json();
      setInboxMessages(data.messages || []);
    } catch (error) {
      console.error('Error fetching inbox messages:', error);
      setActionFeedback({ type: 'error', message: 'Error fetching inbox messages.' });
    }
  };

  const fetchSentMessages = async () => {
    try {
      const res = await fetch(`${apiUrl}/messages/sent`, {
        credentials: 'include',
      });

      if (!res.ok) {
        if (res.status === 401) {
          navigate('/login');
        }
        const errorText = await res.text();
        throw new Error(errorText || 'Failed to fetch sent messages');
      }

      const data = await res.json();
      setSentMessages(data.messages || []);
    } catch (error) {
      console.error('Error fetching sent messages:', error);
      setActionFeedback({ type: 'error', message: 'Error fetching sent messages.' });
    }
  };

  const setupWebSocket = useCallback(() => {
    if (!websocketUrl) {
      console.error('WebSocket URL is not defined.');
      setActionFeedback({ type: 'error', message: 'WebSocket URL is not defined.' });
      return;
    }

    ws.current = new WebSocket(websocketUrl);

    ws.current.onopen = () => {
      // Optionally, send an authentication message if required by the server
      // ws.current.send(JSON.stringify({ type: 'authenticate', token: 'YOUR_TOKEN' }));
      console.log('WebSocket connection established.');
    };

    ws.current.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        handleWebSocketMessage(data);
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };

    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
      setActionFeedback({ type: 'error', message: 'WebSocket encountered an error.' });
    };

    ws.current.onclose = (event) => {
      console.warn('WebSocket connection closed:', event);
      setActionFeedback({ type: 'info', message: 'WebSocket connection closed. Attempting to reconnect...' });
      // Attempt to reconnect after a delay
      reconnectTimer.current = setTimeout(() => {
        setupWebSocket();
      }, reconnectInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocketUrl]); // Depend on websocketUrl

  const handleWebSocketMessage = useCallback((data) => {
    switch (data.type) {
      case 'new_message':
        // Handle incoming new message
        const newMsg = data.payload;

        // Determine message type based on sender
        const type = newMsg.senderEmail === userEmail ? 'sent' : 'received';

        // Avoid duplicates
        setMergedMessages((prevMessages) => {
          const exists = prevMessages.some(msg => msg.id === newMsg.id);
          if (exists) return prevMessages;
          return [{ ...newMsg, type }, ...prevMessages];
        });

        setActionFeedback({ type: 'info', message: `New message ${type === 'sent' ? 'sent' : 'received'}.` });
        break;
      // Handle other message types if needed
      default:
        console.warn('Unknown message type:', data.type);
    }
  }, [userEmail]);

  const handleViewMessage = (message) => {
    setSelectedMessage(message);
    setShowModal(true);

    // Optionally, mark the message as read when viewed (only for received messages)
    if (message.type === 'received' && !message.isRead) {
      markAsRead(message.id);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMessage(null);
    setShowReplyForm(false);
    setReplyContent('');
  };

  const markAsRead = async (messageId) => {
    try {
      const res = await fetch(`${apiUrl}/messages/${messageId}/read`, {
        method: 'PUT',
        credentials: 'include',
      });

      if (!res.ok) {
        const errorText = await res.text();
        throw new Error(errorText || 'Failed to mark message as read');
      }

      const data = await res.json();
      setMergedMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === messageId ? { ...msg, isRead: true } : msg
        )
      );
      setActionFeedback({ type: 'success', message: 'Message marked as read.' });
    } catch (error) {
      console.error(error);
      setActionFeedback({ type: 'error', message: 'Error marking message as read.' });
    }
  };

  const toggleReadStatus = async (messageId, currentStatus) => {
    const url = `${apiUrl}/messages/${messageId}/read`;
    const method = currentStatus ? 'DELETE' : 'PUT'; // DELETE marks as unread

    try {
      const res = await fetch(url, {
        method: method,
        credentials: 'include',
      });

      if (!res.ok) {
        const errorText = await res.text();
        throw new Error(errorText || 'Failed to update read status');
      }

      const data = await res.json();
      setMergedMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === messageId ? { ...msg, isRead: !currentStatus } : msg
        )
      );
      setActionFeedback({ type: 'success', message: `Message marked as ${!currentStatus ? 'read' : 'unread'}.` });
    } catch (error) {
      console.error(error);
      setActionFeedback({ type: 'error', message: 'Error updating read status.' });
    }
  };

  const handleDeleteMessage = async (messageId) => {
    if (!window.confirm('Are you sure you want to delete this message?')) {
      return;
    }

    try {
      const res = await fetch(`${apiUrl}/messages/${messageId}`, {
        method: 'DELETE',
        credentials: 'include',
      });

      if (!res.ok) {
        const errorText = await res.text();
        throw new Error(errorText || 'Failed to delete message');
      }

      const data = await res.json();
      setMergedMessages((prevMessages) =>
        prevMessages.filter((msg) => msg.id !== messageId)
      );
      setActionFeedback({ type: 'success', message: 'Message deleted successfully.' });
    } catch (error) {
      console.error('Error deleting message:', error);
      setActionFeedback({ type: 'error', message: 'Error deleting message.' });
    }
  };

  const handleReply = () => {
    setShowReplyForm(true);
  };

  const handleReplySubmit = async (e) => {
    e.preventDefault();

    if (!replyContent.trim()) {
      setActionFeedback({ type: 'error', message: 'Reply content cannot be empty.' });
      return;
    }

    const recipientEmail = selectedMessage.type === 'received' ? selectedMessage.senderEmail : selectedMessage.recipientEmail;

    if (!recipientEmail) {
      setActionFeedback({ type: 'error', message: 'Recipient email is missing.' });
      return;
    }

    setIsSending(true);

    try {
      const res = await fetch(`${apiUrl}/messages/send`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recipientEmail: recipientEmail, // Ensure this matches backend's MessageRequest
          content: replyContent.trim(),
        }),
      });

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.error || 'Failed to send reply');
      }

      const data = await res.json();
      setActionFeedback({ type: 'success', message: 'Reply sent successfully.' });
      setShowReplyForm(false);
      setReplyContent('');
      fetchSentMessages(); // Refresh sent messages
    } catch (error) {
      console.error('Error sending reply:', error);
      setActionFeedback({ type: 'error', message: 'Error sending reply.' });
    } finally {
      setIsSending(false);
    }
  };

  // Render Feedback Animation based on actionFeedback state using Animate.css
  const renderFeedbackAnimation = () => {
    if (!actionFeedback.type) return null;

    let animationClass = '';
    let animationDuration = '1s'; // Default duration

    switch (actionFeedback.type) {
      case 'success':
        animationClass = 'animate__animated animate__fadeInDown';
        break;
      case 'error':
        animationClass = 'animate__animated animate__shakeX';
        break;
      case 'info':
        animationClass = 'animate__animated animate__fadeIn';
        break;
      default:
        return null;
    }

    return (
      <div className={`feedback-message ${animationClass}`}>
        <p>{actionFeedback.message}</p>
      </div>
    );
  };

  return (
    <div className="inbox-page">
      <ToastContainer />
      {isLoading ? (
        <div className="loading-container animate__animated animate__fadeIn">
          <div className="spinner"></div>
          <p>Loading messages...</p>
        </div>
      ) : groupedMessages.length === 0 ? (
        <p className="no-messages animate__animated animate__fadeIn">No messages found.</p>
      ) : (
        <div className="grouped-message-list">
          {groupedMessages.map(group => (
            <div key={group.userEmail} className="message-group">
              <h3 className="group-header animate__animated animate__fadeInDown">{group.userEmail}</h3>
              <ul className="message-list">
                {group.messages.map(msg => (
                  <li
                    key={msg.id}
                    className={`message-item ${msg.isRead ? 'read' : 'unread'} ${msg.type === 'sent' ? 'sent' : 'received'} animate__animated animate__fadeIn`}
                    onClick={() => handleViewMessage(msg)}
                    tabIndex="0"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') handleViewMessage(msg);
                    }}
                    aria-label={`Message ${msg.type === 'sent' ? 'sent to' : 'received from'} ${msg.type === 'sent' ? msg.recipientEmail : msg.senderEmail}`}
                  >
                    <div className="message-summary">
                      <div className="message-from">
                        <strong>{msg.type === 'sent' ? 'To:' : 'From:'}</strong> {msg.type === 'sent' ? msg.recipientEmail : msg.senderEmail}
                      </div>
                      <div className="message-content">
                        <strong>Content:</strong> {msg.content.length > 50 ? `${msg.content.substring(0, 50)}...` : msg.content}
                      </div>
                      <div className="message-date">
                        <strong>Date:</strong> {new Date(msg.timestamp).toLocaleString()}
                      </div>
                      {msg.type === 'received' && !msg.isRead && <span className="new-badge">New</span>}
                      {msg.type === 'sent' && (
                        <span className={`read-status ${msg.isRead ? 'read' : 'unread-recipient'}`}>
                          {msg.isRead ? 'Read by Recipient' : 'Unread by Recipient'}
                        </span>
                      )}
                    </div>
                    <div className="message-actions">
                      {msg.type === 'received' && (
                        <button
                          className="mark-read-button"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering view message
                            toggleReadStatus(msg.id, msg.isRead);
                          }}
                          aria-label={`Mark message as ${msg.isRead ? 'unread' : 'read'}`}
                        >
                          {msg.isRead ? 'Mark as Unread' : 'Mark as Read'}
                        </button>
                      )}
                      <button
                        className="delete-button"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering view message
                          handleDeleteMessage(msg.id);
                        }}
                        aria-label="Delete message"
                      >
                        Delete
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}

      {/* Feedback Animation */}
      {renderFeedbackAnimation()}

      {/* Message Modal */}
      {showModal && selectedMessage && (
        <div className="modal-overlay" onClick={handleCloseModal} role="dialog" aria-modal="true" aria-labelledby="modal-title">
          <div className="modal-content animate__animated animate__zoomIn" onClick={(e) => e.stopPropagation()}>
            <h3 id="modal-title">{selectedMessage.type === 'sent' ? `Message to ${selectedMessage.recipientEmail}` : `Message from ${selectedMessage.senderEmail}`}</h3>
            <p className="modal-message-content">{selectedMessage.content}</p>
            <div className="modal-actions">
              {selectedMessage.type === 'received' && (
                <button className="reply-button animate__animated animate__fadeIn" onClick={handleReply} aria-label="Reply to message">
                  Reply
                </button>
              )}
              <button className="close-modal-button animate__animated animate__fadeIn" onClick={handleCloseModal} aria-label="Close modal">
                Close
              </button>
            </div>
            {showReplyForm && (
              <form className="reply-form animate__animated animate__fadeIn" onSubmit={handleReplySubmit}>
                <label htmlFor="replyContent">Your Reply:</label>
                <textarea
                  id="replyContent"
                  value={replyContent}
                  onChange={(e) => setReplyContent(e.target.value)}
                  required
                  placeholder="Type your reply here..."
                ></textarea>
                <button type="submit" className="send-reply-button animate__animated animate__pulse" aria-label="Send reply" disabled={isSending}>
                  {isSending ? (
                    <div className="spinner small"></div>
                  ) : (
                    'Send Reply'
                  )}
                </button>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Inbox;
