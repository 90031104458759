// src/context/AuthContext.js

import React, { createContext, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// Create the Auth Context
export const AuthContext = createContext();

// Utility function to normalize user data
const normalizeUser = (data) => {
  if (data.user && data.user.id && data.user.email) {
    return data.user;
  } else if (data.id && data.email) {
    return data;
  } else {
    return null;
  }
};

// Create the AuthProvider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // User information
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  // Function to check authentication status and fetch user info
  const checkAuth = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/user-info`;
      console.log('AuthContext: Fetching user info from:', url);

      const response = await fetch(url, {
        credentials: 'include', // Include cookies
      });

      if (response.ok) {
        const data = await response.json();
        console.log('AuthContext: Received user data:', data);
        const normalizedUser = normalizeUser(data);
        setUser(normalizedUser);
      } else if (response.status === 401) {
        // User is not authenticated; handle this silently
        console.log('AuthContext: User not authenticated.');
        setUser(null);
      } else {
        // Handle other non-OK responses
        let errorMessage = 'Unknown error';
        try {
          const errorData = await response.json();
          errorMessage = errorData.error || errorMessage;
        } catch (parseError) {
          // Unable to parse error message
          errorMessage = 'Failed to parse error message';
        }
        console.error('AuthContext: Unexpected error fetching user info:', errorMessage);
        setUser(null);
      }
    } catch (error) {
      console.error('AuthContext: Error fetching user info:', error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  // Check authentication status on component mount
  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to log the user in
  const login = async (credentials) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
        credentials: 'include', // Include cookies
      });

      if (response.ok) {
        const data = await response.json();
        console.log('AuthContext: Login successful, received data:', data);
        // Instead of relying on response data, fetch user info
        await checkAuth();
        navigate('/dashboard'); // Redirect to dashboard after login
        return { success: true };
      } else {
        let errorMessage = 'Login failed';
        try {
          const errorData = await response.json();
          errorMessage = errorData.error || errorMessage;
        } catch (parseError) {
          // Unable to parse error message
          errorMessage = 'Failed to parse error message';
        }
        console.error('AuthContext: Login failed:', errorMessage);
        return { success: false, error: errorMessage };
      }
    } catch (error) {
      console.error('AuthContext: Login error:', error);
      return { success: false, error: 'An error occurred during login.' };
    }
  };

  // Function to sign up a new user
  const signup = async (formData) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/create_account`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('AuthContext: Signup successful, received data:', data);
        // Instead of relying on response data, fetch user info
        await checkAuth();
        navigate('/dashboard'); // Redirect after successful signup
        return { success: true };
      } else {
        let errorMessage = 'Failed to create account.';
        try {
          const errorData = await response.json();
          errorMessage = errorData.error || errorMessage;
        } catch (parseError) {
          // Unable to parse error message
          errorMessage = 'Failed to parse error message';
        }
        console.error('AuthContext: Signup failed:', errorMessage);
        return { success: false, error: errorMessage };
      }
    } catch (error) {
      console.error('AuthContext: Signup error:', error);
      return { success: false, error: 'An error occurred during account creation.' };
    }
  };

  // Function to log the user out
  const logout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
        method: 'POST',
        credentials: 'include', // Include cookies
      });

      if (response.ok) {
        console.log('AuthContext: Logout successful.');
        setUser(null);
        navigate('/login'); // Redirect to login page
      } else {
        let errorMessage = 'Logout failed';
        try {
          const errorData = await response.json();
          errorMessage = errorData.error || errorMessage;
        } catch (parseError) {
          errorMessage = 'Failed to parse error message';
        }
        console.error('AuthContext: Logout failed:', errorMessage);
      }
    } catch (error) {
      console.error('AuthContext: Logout error:', error);
    }
  };

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({
    user,
    setUser,
    login,
    logout,
    signup,
    loading,
  }), [user, loading]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};
