// src/components/ProtectedLayout.js

import React, { useState, useEffect, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import TopBar from './TopBar'; // Ensure the path is correct
import PropTypes from 'prop-types'; // Import PropTypes for type checking
import { ThemeContext } from '../context/ThemeContext'; // Import ThemeContext

function ProtectedLayout({
  onLogout,
  onEditProfile,
  onInbox,
  onConnections,
  onConnectionRequest,
  onSearchUsers,
}) {
  // Consume ThemeContext
  const { theme, toggleTheme } = useContext(ThemeContext);

  // State to manage connection status
  const [connectionStatus, setConnectionStatus] = useState('connected');

  useEffect(() => {
    // Handler for online event
    const handleOnline = () => {
      setConnectionStatus('connected');
    };

    // Handler for offline event
    const handleOffline = () => {
      setConnectionStatus('disconnected');
    };

    // Add event listeners
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Initial status check
    if (!navigator.onLine) {
      setConnectionStatus('disconnected');
    }

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      <TopBar
        onSearchUsers={onSearchUsers}
        connectionStatus={connectionStatus}
        // Removed onToggleTheme and currentTheme props
      />
      <main>
        <Outlet />
      </main>
    </>
  );
}

ProtectedLayout.propTypes = {
  onLogout: PropTypes.func.isRequired,
  onEditProfile: PropTypes.func.isRequired,
  onInbox: PropTypes.func.isRequired,
  onConnections: PropTypes.func.isRequired,
  onConnectionRequest: PropTypes.func.isRequired,
  onSearchUsers: PropTypes.func.isRequired,
  // Removed onToggleTheme and currentTheme as they're handled via ThemeContext
};

export default ProtectedLayout;
