// src/pages/ConnectionRequests.js

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './ConnectionRequests.css';

function ConnectionRequests() {
  const [pendingRequests, setPendingRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch pending connection requests
  const fetchPendingRequests = useCallback(() => {
    fetch(`${apiUrl}/connections/pending`, {
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch pending connection requests');
        }
        return response.json();
      })
      .then((data) => {
        setPendingRequests(data.pendingRequests || []);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching pending requests:', error);
        setIsLoading(false);
        // Optionally, display an error message to the user
        alert('Error fetching pending connection requests.');
      });
  }, [apiUrl]);

  useEffect(() => {
    fetchPendingRequests();
  }, [fetchPendingRequests]);

  // Handle Approving a Connection Request
  const handleApproveRequest = useCallback(
    (id) => {
      const requesterId = Number(id);
      if (isNaN(requesterId)) {
        console.error('Invalid requesterId:', id);
        alert('Invalid connection request ID.');
        return;
      }

      fetch(`${apiUrl}/connections/approve`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ requesterId: requesterId }),
      })
        .then((response) => {
          return response.text().then((text) => {
            if (!response.ok) {
              throw new Error(text || 'Failed to approve connection request');
            }
            return text;
          });
        })
        .then((data) => {
          setPendingRequests((prev) => prev.filter((request) => request.requesterId !== id));
          alert('Connection request approved successfully!');
        })
        .catch((error) => {
          console.error('Error approving connection request:', error);
          alert(`Error approving request: ${error.message}`);
        });
    },
    [apiUrl]
  );

  // Handle Declining a Connection Request
  const handleDeclineRequest = useCallback(
    (id) => {
      const requesterId = Number(id);
      if (isNaN(requesterId)) {
        console.error('Invalid requesterId:', id);
        alert('Invalid connection request ID.');
        return;
      }

      fetch(`${apiUrl}/connections/decline`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ requesterId: requesterId }),
      })
        .then((response) => {
          return response.text().then((text) => {
            if (!response.ok) {
              throw new Error(text || 'Failed to decline connection request');
            }
            return text;
          });
        })
        .then((data) => {
          setPendingRequests((prev) => prev.filter((request) => request.requesterId !== id));
          alert('Connection request declined successfully!');
        })
        .catch((error) => {
          console.error('Error declining connection request:', error);
          alert(`Error declining request: ${error.message}`);
        });
    },
    [apiUrl]
  );

  // Removed the unused handleBack function
  // Removed related commented-out BackButton import and JSX

  return (
    <div className="connection-requests-page">
      <header className="connection-requests-header">
      </header>

      {isLoading ? (
        <p>Loading pending requests...</p>
      ) : pendingRequests.length === 0 ? (
        <p>No pending connection requests.</p>
      ) : (
        <ul className="pending-requests-list">
          {pendingRequests.map((request) => (
            <li key={request.requesterId} className="pending-request-item">
              <div className="requester-info">
                <img
                  src={request.avatarUrl || '/default-avatar.jpg'}
                  alt={`${request.requesterName}'s avatar`}
                  className="requester-avatar"
                />
                <div className="requester-details">
                  <h3>{request.requesterName}</h3>
                  <p>{request.requesterEmail || 'Email not available'}</p>
                  <p className="request-timestamp">
                    Requested on: {new Date(request.timestamp).toLocaleString()}
                  </p>
                </div>
              </div>
              <div className="request-actions">
                <button
                  className="approve-button"
                  onClick={() => handleApproveRequest(request.requesterId)}
                  aria-label={`Approve request from ${request.requesterName}`}
                >
                  ✅ Approve
                </button>
                <button
                  className="decline-button"
                  onClick={() => handleDeclineRequest(request.requesterId)}
                  aria-label={`Decline request from ${request.requesterName}`}
                >
                  ❌ Decline
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default ConnectionRequests;
