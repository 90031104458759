// src/components/UserProfile.js

import React, { useEffect, useState, useRef, useContext } from 'react';
import './UserProfile.css';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../context/AuthContext'; // Import AuthContext

function UserProfile() {
  const { user, loading: authLoading, setUser } = useContext(AuthContext); // Access 'user', 'loading', and 'setUser' from AuthContext
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    address: '',
    dateOfBirth: '',
    bio: '',
    avatarUrl: '',
  });
  const [loading, setLoading] = useState(true); // Local loading state
  const [errors, setErrors] = useState({});
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [avatarFile, setAvatarFile] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (authLoading) {
      // Wait for AuthContext to finish loading
      return;
    }

    if (!user) {
      // If user is not authenticated, redirect to login
      navigate('/login');
      return;
    }

    // Fetch user profile data
    const fetchUserProfile = async () => {
      try {
        const res = await fetch(`${apiUrl}/user-profile`, {
          credentials: 'include',
        });

        if (!res.ok) {
          const errorText = await res.text();
          console.error('Error Response:', errorText);
          throw new Error('Failed to fetch user profile');
        }

        const data = await res.json();
        setProfile({
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          phoneNumber: data.phoneNumber || '',
          address: data.address || '',
          dateOfBirth: data.dateOfBirth ? data.dateOfBirth.split('T')[0] : '',
          bio: data.bio || '',
          avatarUrl: data.avatarUrl || '',
        });
        setAvatarPreview(data.avatarUrl || '/default-avatar.png');
        setLoading(false);
      } catch (err) {
        console.error(err);
        toast.error('Error fetching profile');
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [apiUrl, user, navigate, authLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error for the field
    setErrors((prev) => ({
      ...prev,
      [name]: '',
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!profile.firstName.trim()) newErrors.firstName = 'First name is required';
    if (!profile.lastName.trim()) newErrors.lastName = 'Last name is required';
    if (profile.phoneNumber && !/^\+?[1-9]\d{1,14}$/.test(profile.phoneNumber)) {
      newErrors.phoneNumber = 'Invalid phone number format';
    }
    // Add more validations as needed
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type (optional)
      if (!file.type.startsWith('image/')) {
        toast.error('Please select a valid image file.');
        return;
      }
      setAvatarFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      toast.error('Please fix the errors in the form.');
      return;
    }

    const formData = new FormData();
    formData.append('firstName', profile.firstName.trim());
    formData.append('lastName', profile.lastName.trim());
    formData.append('phoneNumber', profile.phoneNumber.trim());
    formData.append('address', profile.address.trim());
    formData.append('dateOfBirth', profile.dateOfBirth || '');
    formData.append('bio', profile.bio.trim());
    if (avatarFile) {
      formData.append('avatar', avatarFile);
    }

    try {
      const res = await fetch(`${apiUrl}/user-profile`, {
        method: 'PUT',
        credentials: 'include',
        body: formData,
      });

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.error || 'Failed to update profile');
      }

      const data = await res.json();

      // Update AuthContext with the updated user data
      if (data.user) {
        setUser(data.user);
      } else {
        // If the response does not contain user data, fetch it again
        const refreshedRes = await fetch(`${apiUrl}/user-info`, {
          credentials: 'include',
        });
        if (refreshedRes.ok) {
          const refreshedData = await refreshedRes.json();
          setUser(refreshedData.user);
        }
      }

      toast.success('Profile updated successfully!');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error(error.message || 'An error occurred. Please try again.');
    }
  };

  if (authLoading || loading) {
    return (
      <div className="user-profile">
        <div className="loading">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="user-profile">
      {/* Header */}
      <div className="profile-header">
        <h1>Edit Profile</h1>
      </div>

      <ToastContainer />
      <form onSubmit={handleSubmit} className="profile-form">
        {/* Avatar Section */}
        <div className="avatar-section">
          <img
            src={avatarPreview || '/default-avatar.png'}
            alt="User Avatar"
            className="avatar-preview"
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleAvatarChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
            aria-label="Upload avatar"
          />
          <button
            type="button"
            className="btn btn-secondary upload-avatar-button"
            onClick={() => fileInputRef.current.click()}
          >
            Change Avatar
          </button>
        </div>

        {/* Personal Information */}
        <div className="form-group">
          <label htmlFor="firstName">
            First Name<span className="required">*</span>:
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={profile.firstName}
            onChange={handleChange}
            aria-required="true"
            aria-invalid={errors.firstName ? 'true' : 'false'}
          />
          {errors.firstName && <span className="error">{errors.firstName}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="lastName">
            Last Name<span className="required">*</span>:
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={profile.lastName}
            onChange={handleChange}
            aria-required="true"
            aria-invalid={errors.lastName ? 'true' : 'false'}
          />
          {errors.lastName && <span className="error">{errors.lastName}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={profile.phoneNumber}
            onChange={handleChange}
            placeholder="+1234567890"
            aria-invalid={errors.phoneNumber ? 'true' : 'false'}
          />
          {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="address">Address:</label>
          <input
            type="text"
            id="address"
            name="address"
            value={profile.address}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="dateOfBirth">Date of Birth:</label>
          <input
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            value={profile.dateOfBirth}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="bio">Bio:</label>
          <textarea
            id="bio"
            name="bio"
            value={profile.bio}
            onChange={handleChange}
            placeholder="Tell us something about yourself..."
          ></textarea>
        </div>

        {/* Submit Button */}
        <button type="submit" className="btn btn-primary save-button">
          Save Changes
        </button>
      </form>
    </div>
  );
}

export default UserProfile;
