// src/components/Dashboard.js

import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import './Dashboard.css';
import { useNavigate } from 'react-router-dom';
import FeedItem from './FeedItem';
import { compressImage } from '../utils/imageCompression';
import { WebSocketContext } from '../context/WebSocketContext';
import { AuthContext } from '../context/AuthContext';

function Dashboard() {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  // Use the WebSocket context
  const {
    notifications,
    setNotifications,
    activities,
    setActivities,
    feedItems,
    setFeedItems,
    liveFeedItems,
    setLiveFeedItems,
    currentUserId,
    setCurrentUserId,
  } = useContext(WebSocketContext);

  // Get user from AuthContext
  const { user } = useContext(AuthContext);

  const [searchTerm, setSearchTerm] = useState('');
  const [showActivitiesPopup, setShowActivitiesPopup] = useState(false);
  const [showNotificationsPopup, setShowNotificationsPopup] = useState(false);

  // Feed-related state variables
  const [newPostContent, setNewPostContent] = useState('');
  const [newPostImageFile, setNewPostImageFile] = useState(null);
  const [newPostImagePreview, setNewPostImagePreview] = useState(null);
  const [isPosting, setIsPosting] = useState(false);

  const [activeTab, setActiveTab] = useState('feed');

  // Comment Visibility State Variables
  const [visibleComments, setVisibleComments] = useState({});

  // Handle Toggle Comments
  const handleToggleComments = useCallback((feedItemId) => {
    setVisibleComments((prevState) => ({
      ...prevState,
      [feedItemId]: !prevState[feedItemId],
    }));
  }, []);

  // Set current user ID from AuthContext
  useEffect(() => {
    if (user) {
      console.log('Dashboard: User data:', user);
      if (user.id !== undefined && user.id !== null) {
        const userId = Number(user.id);
        if (!isNaN(userId)) {
          setCurrentUserId(userId);
        } else {
          console.error('Invalid user ID format:', user.id);
          setCurrentUserId(null); // Or handle accordingly
          navigate('/login'); // Redirect to login if ID is invalid
        }
      } else {
        console.error('User ID is undefined or null:', user);
        setCurrentUserId(null);
        navigate('/login'); // Redirect to login if ID is missing
      }
    } else {
      navigate('/login');
    }
  }, [user, navigate, setCurrentUserId]);

  // Fetch Feed Items
  const fetchFeedItems = useCallback(() => {
    fetch(`${apiUrl}/feed`, {
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch feed items');
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setFeedItems(data);
        } else {
          console.error('Unexpected feed data format:', data);
          setFeedItems([]); // Fallback to prevent errors
        }
      })
      .catch((error) => {
        console.error('Error fetching feed items:', error);
        setFeedItems([]); // Optionally reset feedItems on error
      });
  }, [apiUrl, setFeedItems]);

  // Fetch feed items when component mounts or user changes
  useEffect(() => {
    if (user) {
      fetchFeedItems();
    }
  }, [user, fetchFeedItems]);

  // Fetch User Activities
  const fetchUserActivities = useCallback(() => {
    fetch(`${apiUrl}/user-activity`, {
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch user activities');
        }
        return response.json();
      })
      .then((data) => {
        if (data.activities && Array.isArray(data.activities)) {
          setActivities(data.activities);
        } else {
          console.error('Unexpected user activities data format:', data);
          setActivities([]); // Fallback
        }
      })
      .catch((error) => {
        console.error('Error fetching user activity:', error);
      });
  }, [apiUrl, setActivities]);

  // Fetch activities on component mount
  useEffect(() => {
    if (user) {
      fetchUserActivities();
    }
  }, [user, fetchUserActivities]);

  // Fetch Live Feed Items
  const [isLiveFeedLoading, setIsLiveFeedLoading] = useState(false);

  const fetchLiveFeedItems = useCallback(() => {
    setIsLiveFeedLoading(true);
    fetch(`${apiUrl}/live-feed`, {
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch live feed items');
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setLiveFeedItems(data);
        } else {
          console.error('Unexpected live feed data format:', data);
          setLiveFeedItems([]); // Fallback to prevent errors
        }
        setIsLiveFeedLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching live feed items:', error);
        setLiveFeedItems([]); // Optionally reset liveFeedItems on error
        setIsLiveFeedLoading(false);
      });
  }, [apiUrl, setLiveFeedItems]);

  // Fetch live feed items on component mount and when user is set
  useEffect(() => {
    if (user) {
      fetchLiveFeedItems();
    }
  }, [user, fetchLiveFeedItems]);

  // Handle Image Upload for New Posts
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp', 'heic', 'heif'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      // Validate file extension
      if (!allowedExtensions.includes(fileExtension)) {
        alert(`Unsupported file type. Allowed types: ${allowedExtensions.join(', ')}.`);
        return;
      }

      try {
        // Compress the image before setting it
        const compressedFile = await compressImage(file, {
          maxSizeMB: 1, // Adjust based on your requirements
          maxWidthOrHeight: 1920, // Adjust based on your requirements
        });

        setNewPostImageFile(compressedFile);
        setNewPostImagePreview(URL.createObjectURL(compressedFile));
      } catch (error) {
        console.error('Image compression failed:', error);
        alert('Failed to compress the image. Please try a different image or try again.');
      }
    }
  };

  const handleRemoveImage = () => {
    if (newPostImagePreview) {
      URL.revokeObjectURL(newPostImagePreview); // Clean up the object URL
    }
    setNewPostImageFile(null);
    setNewPostImagePreview(null);
  };

  // Cleanup object URL when component unmounts or when newPostImagePreview changes
  useEffect(() => {
    return () => {
      if (newPostImagePreview) {
        URL.revokeObjectURL(newPostImagePreview);
      }
    };
  }, [newPostImagePreview]);

  // Handle Posting a New Feed Item
  const handlePostFeedItem = useCallback(async () => {
    // Validate post content
    if (!newPostContent.trim()) {
      console.warn('Post content is empty.');
      return;
    }

    setIsPosting(true); // Start loading

    let imageUrl = null;

    // If an image is selected, upload it first
    if (newPostImageFile) {
      try {
        const formData = new FormData();

        // Ensure the file has a valid name before appending
        const fileName = newPostImageFile.name || 'image.jpg';
        formData.append('image', newPostImageFile, fileName);

        const uploadResponse = await fetch(`${apiUrl}/upload`, {
          method: 'POST',
          credentials: 'include',
          body: formData,
        });

        if (!uploadResponse.ok) {
          const errorData = await uploadResponse.json();
          throw new Error(errorData.error || 'Image upload failed.');
        }

        const uploadData = await uploadResponse.json();
        imageUrl = uploadData.imageUrl; // Adjust based on your backend's response structure
      } catch (error) {
        console.error('Error uploading image:', error);
        alert('Failed to upload image. Please try again.');
        setIsPosting(false);
        return;
      }
    }

    const postPayload = {
      content: newPostContent.trim(),
      imageUrl: imageUrl, // This will be null if no image was uploaded
    };

    try {
      const response = await fetch(`${apiUrl}/feed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(postPayload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create a new feed post');
      }

      const data = await response.json();

      if (data.feedItem) {
        setFeedItems((prevFeed) => {
          const exists = prevFeed.some((item) => item.id === data.feedItem.id);
          return exists ? prevFeed : [data.feedItem, ...prevFeed];
        });
      } else {
        console.error('Unexpected response format:', data);
      }

      // Reset form fields after posting
      setNewPostContent('');
      setNewPostImageFile(null);
      setNewPostImagePreview(null);
      setIsPosting(false);
    } catch (error) {
      console.error('Error posting new feed item:', error);
      setIsPosting(false);
      alert('Failed to post the feed item. Please try again.');
    }
  }, [apiUrl, newPostContent, newPostImageFile, setFeedItems]);

  // Handle Search Input for Activities
  const handleSearch = useCallback((e) => {
    setSearchTerm(e.target.value.toLowerCase());
  }, []);

  // Filter activities based on search term
  const filteredActivities = useMemo(
    () =>
      activities.filter((activity) =>
        activity.description.toLowerCase().includes(searchTerm)
      ),
    [activities, searchTerm]
  );

  // Handle Like/Unlike Feed Item
  const toggleLike = useCallback(
    async (feedItemId, isCurrentlyLiked) => {
      if (!user) {
        alert('You must be logged in to like posts.');
        return;
      }

      const endpoint = `${apiUrl}/shared-feed/like/${feedItemId}`;

      const options = {
        method: isCurrentlyLiked ? 'DELETE' : 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await fetch(endpoint, options);

        if (response.ok) {
          // Update the local state
          setFeedItems((prevFeedItems) =>
            prevFeedItems.map((item) => {
              if (item.id === feedItemId) {
                const updatedLikesCount = isCurrentlyLiked
                  ? item.likesCount - 1
                  : item.likesCount + 1;
                return {
                  ...item,
                  liked: !isCurrentlyLiked,
                  likesCount: updatedLikesCount,
                };
              }
              return item;
            })
          );
        } else {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to like/unlike the post');
        }
      } catch (error) {
        console.error(
          `Error while trying to ${isCurrentlyLiked ? 'unlike' : 'like'} the post:`,
          error
        );
        alert(
          `An error occurred while trying to ${
            isCurrentlyLiked ? 'unlike' : 'like'
          } the post. Please try again.`
        );
      }
    },
    [apiUrl, user, setFeedItems]
  );

  // Handle Delete Feed Item
  const handleDelete = useCallback(
    async (postId) => {
      const confirmDelete = window.confirm('Are you sure you want to delete this post?');
      if (!confirmDelete) return;

      try {
        const response = await fetch(`${apiUrl}/posts/${postId}`, {
          method: 'DELETE',
          credentials: 'include', // Include cookies in the request
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          // Remove the post from feedItems
          setFeedItems((prevFeedItems) => prevFeedItems.filter((item) => item.id !== postId));

          // Similarly, remove from liveFeedItems if present
          setLiveFeedItems((prevLiveFeed) => prevLiveFeed.filter((item) => item.id !== postId));
        } else {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to delete the post.');
        }
      } catch (error) {
        console.error('Error deleting post:', error);
        alert('Failed to delete the post. Please try again.');
      }
    },
    [apiUrl, setFeedItems, setLiveFeedItems]
  );

  // Accessibility: Close Modals When 'Escape' Key is Pressed
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setShowActivitiesPopup(false);
        setShowNotificationsPopup(false);
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <div className="dashboard-page">
      {/* Skip to Content Link */}
      <a href="#main-content" className="skip-link">
        Skip to main content
      </a>

      {/* Main Dashboard Container */}
      <div className="dashboard-container">
        {/* Main Content Area */}
        <div className="main-content" id="main-content" tabIndex="-1">
          {/* Feeds Tabs for Mobile */}
          <div className="feeds-tabs" role="tablist" aria-label="Feed Tabs">
            <button
              className={`tab-button ${activeTab === 'feed' ? 'active' : ''}`}
              onClick={() => setActiveTab('feed')}
              aria-selected={activeTab === 'feed'}
              role="tab"
              id="tab-feed"
              aria-controls="feed"
            >
              Feed
            </button>
            <button
              className={`tab-button ${activeTab === 'live-feed' ? 'active' : ''}`}
              onClick={() => setActiveTab('live-feed')}
              aria-selected={activeTab === 'live-feed'}
              role="tab"
              id="tab-live-feed"
              aria-controls="live-feed"
            >
              Live Feed
            </button>
          </div>

          {/* Feeds Container */}
          <div className="feeds-container">
            {/* Feed Section */}
            {activeTab === 'feed' && (
              <div className="feed-section" id="feed" role="tabpanel" aria-labelledby="tab-feed">
                <h2>Feed</h2>
                <div className="new-post">
                  <textarea
                    value={newPostContent}
                    onChange={(e) => setNewPostContent(e.target.value)}
                    placeholder="What's on your mind?"
                    className="new-post-textarea"
                    aria-label="New post content"
                  ></textarea>

                  {/* File Input for Image Upload */}
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg, .gif, .bmp, .webp, .heic, .heif"
                    onChange={handleImageChange}
                    className="new-post-image-input"
                    aria-label="Upload image"
                  />

                  {/* Image Preview */}
                  {newPostImagePreview && (
                    <div className="image-preview">
                      <img src={newPostImagePreview} alt="Preview" />
                      <button
                        onClick={handleRemoveImage}
                        className="remove-image-button"
                        aria-label="Remove image"
                      >
                        &times;
                      </button>
                    </div>
                  )}

                  <button
                    onClick={handlePostFeedItem}
                    disabled={!newPostContent.trim() || isPosting}
                    className="post-button tooltip"
                    aria-label="Post"
                    data-tooltip="Create a new post"
                  >
                    {isPosting ? 'Posting...' : 'Post'}
                  </button>
                </div>

                {/* Feed Items List */}
                <div className="feed-items">
                  {feedItems.length === 0 ? (
                    <p>No feed items to display.</p>
                  ) : (
                    feedItems.map((item) => (
                      <FeedItem
                        key={item.id}
                        item={item}
                        toggleLike={toggleLike}
                        handleToggleComments={handleToggleComments}
                        visibleComments={visibleComments}
                        currentUserId={currentUserId}
                        onDelete={handleDelete}
                      />
                    ))
                  )}
                </div>
              </div>
            )}

            {/* Live Feed Section */}
            {activeTab === 'live-feed' && (
              <div
                className="live-feed-section"
                id="live-feed"
                role="tabpanel"
                aria-labelledby="tab-live-feed"
              >
                <h2>Live Feed</h2>
                <div className="live-feed-items">
                  {isLiveFeedLoading ? (
                    <p>Loading live feed...</p>
                  ) : liveFeedItems.length === 0 ? (
                    <p>No live feed items to display.</p>
                  ) : (
                    liveFeedItems.map((item) => (
                      <FeedItem
                        key={item.id}
                        item={item}
                        toggleLike={toggleLike}
                        handleToggleComments={handleToggleComments}
                        visibleComments={visibleComments}
                        currentUserId={currentUserId}
                        onDelete={handleDelete}
                      />
                    ))
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Activities Popup */}
        {showActivitiesPopup && (
          <div
            className="activities-popup"
            role="dialog"
            aria-modal="true"
            aria-labelledby="activities-title"
          >
            <div
              className="popup-overlay"
              onClick={() => setShowActivitiesPopup(false)}
              aria-label="Close Activities Popup"
            ></div>
            <div className="popup-content">
              <button
                className="close-popup tooltip"
                onClick={() => setShowActivitiesPopup(false)}
                aria-label="Close Activities Popup"
                data-tooltip="Close"
              >
                &times;
              </button>
              <h2 id="activities-title">Your Recent Activities</h2>
              <div className="popup-controls">
                <input
                  type="text"
                  placeholder="Search activities..."
                  value={searchTerm}
                  onChange={handleSearch}
                  aria-label="Search activities"
                />
                <button
                  onClick={fetchUserActivities}
                  aria-label="Refresh activities"
                  className="tooltip"
                  data-tooltip="Refresh Activities"
                >
                  🔄
                </button>
              </div>
              <ul className="activities-list">
                {filteredActivities.map((activity, index) => (
                  <li key={index}>
                    <span className="activity-icon" aria-hidden="true">
                      {activity.description.includes('Logged in')
                        ? '🔑'
                        : activity.description.includes('Logged out')
                        ? '🔒'
                        : '📄'}
                    </span>
                    <span>
                      {new Date(activity.timestamp).toLocaleString()}: {activity.description}
                    </span>
                  </li>
                ))}
                {filteredActivities.length === 0 && <li>No activities found.</li>}
              </ul>
            </div>
          </div>
        )}

        {/* Notifications Popup */}
        {showNotificationsPopup && (
          <div
            className="notifications-popup"
            role="dialog"
            aria-modal="true"
            aria-labelledby="notifications-title"
          >
            <div
              className="popup-overlay"
              onClick={() => setShowNotificationsPopup(false)}
              aria-label="Close Notifications Popup"
            ></div>
            <div className="popup-content">
              <button
                className="close-popup tooltip"
                onClick={() => setShowNotificationsPopup(false)}
                aria-label="Close Notifications Popup"
                data-tooltip="Close"
              >
                &times;
              </button>
              <h2 id="notifications-title">Notifications</h2>
              <ul className="notifications-list">
                {notifications.length === 0 ? (
                  <li>No notifications.</li>
                ) : (
                  notifications.map((notification, index) => (
                    <li key={index} className={notification.read ? '' : 'unread'}>
                      <span>{notification.message}</span>
                      <span className="notification-timestamp">
                        {notification.timestamp}
                      </span>
                      {!notification.read && (
                        <button
                          onClick={() => {
                            setNotifications((prevNotifications) =>
                              prevNotifications.map((notif, notifIndex) =>
                                notifIndex === index ? { ...notif, read: true } : notif
                              )
                            );
                          }}
                          aria-label="Mark as read"
                        >
                          Mark as read
                        </button>
                      )}
                    </li>
                  ))
                )}
              </ul>
              <button
                onClick={() => {
                  // Mark all as read
                  setNotifications((prevNotifications) =>
                    prevNotifications.map((notif) => ({ ...notif, read: true }))
                  );
                }}
                className="mark-all-read-button tooltip"
                aria-label="Mark all as read"
                data-tooltip="Mark all as read"
              >
                Mark all as read
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
