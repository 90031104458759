// src/context/WebSocketContext.js

import React, { createContext, useEffect, useRef, useState, useCallback, useContext, useMemo } from 'react';
import { AuthContext } from './AuthContext'; // Adjusted path as necessary

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL;
  const wsRef = useRef(null);
  const messageQueueRef = useRef([]);
  
  // States managed by WebSocketContext
  const [notifications, setNotifications] = useState([]);
  const [activities, setActivities] = useState([]);
  const [feedItems, setFeedItems] = useState([]);
  const [liveFeedItems, setLiveFeedItems] = useState([]);
  const [error, setError] = useState(null); // To track connection errors

  // Get user and setUser from AuthContext
  const { user, setUser } = useContext(AuthContext); // Accessing setUser for potential user updates

  // State for currentUserId
  const [currentUserId, setCurrentUserId] = useState(null);

  // Function to handle WebSocket messages
  const handleWebSocketMessage = useCallback(
    (data) => {
      switch (data.type) {
        case 'activity':
          setActivities((prevActivities) => [data.payload, ...prevActivities]);
          break;
        case 'feed':
          setFeedItems((prevFeed) => {
            const exists = prevFeed.some((item) => item.id === data.payload.id);
            return exists ? prevFeed : [data.payload, ...prevFeed];
          });
          break;
        case 'new_comment':
          const newComment = data.payload;
          setNotifications((prevNotifications) => [
            {
              id: newComment.id,
              message: `${newComment.userEmail} commented: ${newComment.content}`,
              timestamp: new Date(newComment.timestamp).toLocaleString(),
              read: false,
            },
            ...prevNotifications,
          ]);
          break;
        case 'update_user':
          // Example case where user data is updated via WebSocket
          if (data.payload && data.payload.user) {
            setUser(data.payload.user); // Update AuthContext's user state
          }
          break;
        // Handle other message types similarly
        default:
          console.warn('WebSocketContext: Unhandled message type:', data.type);
      }
    },
    [setUser]
  );

  // Function to establish WebSocket connection
  const establishWebSocketConnection = useCallback(() => {
    if (!currentUserId) {
      console.log('WebSocketContext: No authenticated user. Skipping WebSocket connection.');
      return;
    }

    // Append userId as a query parameter if required by your WebSocket server
    const socketUrl = `${websocketUrl}?userId=${currentUserId}`;

    console.log(`WebSocketContext: Connecting to WebSocket at ${socketUrl}`);
    const socket = new WebSocket(socketUrl);

    wsRef.current = socket;

    socket.onopen = () => {
      console.log('WebSocketContext: WebSocket connection established.');

      // Reset reconnection attempts
      reconnectAttempts.current = 0;

      // Send any queued messages
      if (messageQueueRef.current.length > 0) {
        messageQueueRef.current.forEach((queuedMessage) => {
          socket.send(JSON.stringify(queuedMessage));
        });
        messageQueueRef.current = [];
      }
    };

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        handleWebSocketMessage(data);
      } catch (error) {
        console.error('WebSocketContext: Error parsing WebSocket message:', error);
      }
    };

    socket.onerror = (errorEvent) => {
      console.error('WebSocketContext: WebSocket encountered an error:', errorEvent);
    };

    socket.onclose = (event) => {
      console.warn(`WebSocketContext: WebSocket closed: ${event.reason}`);

      if (reconnectAttempts.current < maxReconnectAttempts) {
        const reconnectInterval = baseReconnectInterval * Math.pow(2, reconnectAttempts.current);
        console.log(`WebSocketContext: Attempting to reconnect in ${reconnectInterval / 1000} seconds...`);
        reconnectAttempts.current += 1;
        reconnectTimer.current = setTimeout(() => {
          establishWebSocketConnection();
        }, reconnectInterval);
      } else {
        console.error('WebSocketContext: Max reconnection attempts reached. Could not reconnect to WebSocket.');
        setError('Could not reconnect to WebSocket.');
      }
    };
  }, [currentUserId, handleWebSocketMessage, websocketUrl]);

  // Reconnection logic variables
  const reconnectAttempts = useRef(0);
  const reconnectTimer = useRef(null);
  const maxReconnectAttempts = 10;
  const baseReconnectInterval = 5000; // in milliseconds

  // Effect to establish WebSocket connection when currentUserId changes
  useEffect(() => {
    if (currentUserId) {
      establishWebSocketConnection();
    }

    return () => {
      // Cleanup WebSocket connection on unmount or when currentUserId changes
      if (wsRef.current) {
        wsRef.current.close();
        console.log('WebSocketContext: WebSocket connection closed.');
      }
      if (reconnectTimer.current) {
        clearTimeout(reconnectTimer.current);
        console.log('WebSocketContext: Reconnection timer cleared.');
      }
    };
  }, [currentUserId, establishWebSocketConnection]);

  // Function to send messages via WebSocket
  const sendMessage = useCallback((message) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify(message));
    } else {
      // Queue the message if WebSocket is not open
      messageQueueRef.current.push(message);
    }
  }, []);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({
    wsRef,
    sendMessage,
    notifications,
    setNotifications,
    activities,
    setActivities,
    feedItems,
    setFeedItems,
    liveFeedItems,
    setLiveFeedItems,
    currentUserId,
    setCurrentUserId,
    error, // To inform consuming components about connection errors
  }), [
    notifications,
    activities,
    feedItems,
    liveFeedItems,
    currentUserId,
    setCurrentUserId,
    error,
    sendMessage,
  ]);

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};
