import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function MessageDetail() {
  const { messageId } = useParams();
  const [message, setMessage] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${apiUrl}/messages/${messageId}`, {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((data) => setMessage(data.message))
      .catch((error) => console.error('Error fetching message:', error));
  }, [apiUrl, messageId]);

  const handleDelete = () => {
    fetch(`${apiUrl}/messages/${messageId}`, {
      method: 'DELETE',
      credentials: 'include',
    })
      .then(() => {
        navigate('/inbox');
      })
      .catch((error) => console.error('Error deleting message:', error));
  };

  if (!message) return <p>Loading...</p>;

  return (
    <div className="message-detail">
      <h2>Message Detail</h2>
      <p>From: {message.senderId}</p>
      <p>{message.content}</p>
      <button onClick={handleDelete}>Delete</button>
    </div>
  );
}

export default MessageDetail;
