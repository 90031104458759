// src/components/ComposeMessage.js

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './ComposeMessage.css';
import { AuthContext } from '../context/AuthContext';

function ComposeMessage() {
  const { userId } = useParams(); // Extract userId from the route parameters
  const [receiverId, setReceiverId] = useState(userId || '');
  const [content, setContent] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { user } = useContext(AuthContext); // Access the user from AuthContext

  // Fetch receiver's details whenever receiverId changes
  useEffect(() => {
    const fetchReceiverDetails = async () => {
      if (!receiverId) return; // No receiverId provided

      try {
        const response = await fetch(`${apiUrl}/api/users/${receiverId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          const errorData = await response.json();
          setError(errorData.error || 'Failed to fetch receiver details.');
          return;
        }

        const data = await response.json();
        setReceiverName(data.user.name); // Assuming API returns { user: { name: '...', ... } }
        setError(''); // Clear any previous errors
      } catch (err) {
        console.error('Error fetching receiver details:', err);
        setError('An unexpected error occurred while fetching receiver details.');
      }
    };

    fetchReceiverDetails();
  }, [receiverId, apiUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      setError('You are not logged in. Please log in.');
      navigate('/login');
      return;
    }

    if (receiverId.trim() === '' || content.trim() === '') {
      setError('Both receiver and message content are required.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await fetch(`${apiUrl}/api/messages/send`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          receiverId: receiverId.trim(),
          content: content.trim(),
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.error || 'Failed to send message.');
        return;
      }

      // Optionally, you can display a success message or redirect
      navigate('/inbox'); // Redirect to Inbox after successful send
    } catch (error) {
      console.error('Error sending message:', error);
      setError('An unexpected error occurred while sending the message.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="compose-message-page">
      <div className="compose-message-container">
        <h2>Compose Message</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit} className="compose-message-form">
          <label htmlFor="receiver">
            To:
            {userId ? (
              <input
                type="text"
                id="receiver"
                value={receiverName || receiverId}
                readOnly
                className="receiver-input"
              />
            ) : (
              <input
                type="text"
                id="receiver"
                value={receiverId}
                onChange={(e) => setReceiverId(e.target.value)}
                placeholder="Enter recipient's ID"
                required
              />
            )}
          </label>
          <label htmlFor="message-content">
            Message:
            <textarea
              id="message-content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="Type your message here..."
              required
            ></textarea>
          </label>
          <button type="submit" disabled={loading}>
            {loading ? 'Sending...' : 'Send'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ComposeMessage;
