// src/components/ImageWithFallback.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ImageWithFallback = ({ src, fallbackSrc, alt, className, ...props }) => {
  const [hasError, setHasError] = useState(false);

  return (
    <img
      src={!hasError && src ? src : fallbackSrc}
      alt={alt}
      className={className}
      onError={() => setHasError(true)}
      {...props}
    />
  );
};

ImageWithFallback.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ImageWithFallback.defaultProps = {
  src: '',
  className: '',
};

export default ImageWithFallback;
